import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { capitalize, get, isEmpty, set } from "lodash";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import withQuery from "with-query";
import { getRuntimeEnv } from "../hoc/runtime-env";
import { GlobalApiHook, GlobalApiHookOptions } from "./api.type";
import tree from "./tree/api.json";
import { replaceParamsToUrl } from "./utils";

const sliceTrees: any = tree;

const getGroupName = (name: string) => {
  return capitalize(`api_${name}`);
};

const getApiEndpoint = (name: string) => {
  return capitalize(`fetch_${name}`);
};

const dynamicBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const baseUrl = getRuntimeEnv("NEXT_PUBLIC_BASE_API_URL");
  // const baseUrl = "https://lylo-admin-api-sit.lylo.com.cn/api";
  // const baseUrl = "https://lylo-backoffice-common-booking-service-sit.uat.lylo.tech/api"
  const newArgs =
    typeof args === "string"
      ? `${baseUrl}/${args}`
      : { ...args, url: `${baseUrl}/${args.url}` };
  return fetchBaseQuery({
    prepareHeaders: (headers) => {
      const customHeaders: any = getStatefulApiHeaders();

      if (!isEmpty(customHeaders)) {
        Object.keys(customHeaders).forEach((key) => {
          headers.set(key, customHeaders[key]);
        });
      }

      return headers;
    },
  })(newArgs, api, extraOptions);
};

const generateAllApis = () => {
  const apis: any = {};
  const reducers: any = {};
  const middlewares: any = {};

  let businessIdFromUrl = "";
  if (typeof window !== "undefined") { 
    if (location.pathname.includes("econtract")) {
      const urlParams = new URLSearchParams(window.location.search);
      businessIdFromUrl = urlParams.get("business_id") || "1";
    }
  }


  Object.keys(sliceTrees).forEach((groupName) => {
    const group = sliceTrees[groupName];
    const api = createApi({
      keepUnusedDataFor: 0,
      reducerPath: getGroupName(groupName),
      baseQuery: dynamicBaseQuery,
      endpoints: (builder) => {
        const endpoints: any = {};

        Object.keys(group).forEach((name) => {
          const {
            url,
            method,
            mutable: defaultMutable,
            responseContentType,
          } = group[name];

          const mutable = defaultMutable ?? method !== "GET";
          const func = mutable
            ? builder.mutation<any, any>
            : builder.query<any, any>;
          const responseHandler =
            responseContentType === "file"
              ? async (res: any) => {
                  const data = window.URL.createObjectURL(await res.blob());
                  return {
                    data,
                  };
                }
              : undefined;

          endpoints[getApiEndpoint(name)] = func({
            query: ({ query, params, body, headers, ...others } = {}) => ({
              ...others,
              headers: {
                ...(headers || {}),
                "Trace-ID": uuidv4(),
                "Business-Id":
                  businessIdFromUrl || sessionStorage.getItem("Business-Id") || 1,
              },
              url: withQuery(replaceParamsToUrl(url, params), query),
              method,
              responseHandler,
              ...(mutable && {
                body,
              }),
            }),
          });
        });

        return endpoints;
      },
    });

    apis[api.reducerPath] = api;
    reducers[api.reducerPath] = api.reducer;
    middlewares[api.reducerPath] = api.middleware;
  });

  return { apis, reducers, middlewares };
};

export const { apis, reducers, middlewares } = generateAllApis();

export const useStatefulApi = <B extends any, D extends any, E extends any>(
  path: string,
  options?: GlobalApiHookOptions<B, D>,
  crudType?: string
): GlobalApiHook<B, D, E> => {
  const dispatch = useDispatch();
  const [groupName, action] = useMemo(() => {
    const [name, ...actions] = path.split(".");
    return [name, actions.join(".")];
  }, [path]);
  const api = apis[getGroupName(groupName)];
  if (!api && !options?.optional) {
    const message = `Seem state "${path}" is not defined in slice "state/tree/api.json"`;
    throw new Error(message);
  }
  const mutable = api?.[`use${getApiEndpoint(action)}Mutation`];
  const useApi =
    api?.[`use${getApiEndpoint(action)}Mutation`] ??
    api?.[`use${getApiEndpoint(action)}Query`];
  let skip = !mutable
    ? {
        skip: options?.skip,
      }
    : undefined;
  if (crudType === "none") {
    skip = {
      skip: true,
    };
  }
  const result = useApi?.(
    !mutable ? options?.defaultQueryPayload : undefined,
    skip
  );
  // if(result?.error?.status === 400) {
  //   debugger;
  // }
  const resetState = useCallback(
    () => api?.util && dispatch(api?.util?.resetApiState()),
    [api?.util, dispatch]
  );

  const updateState = useCallback(
    (newState: any) => {
      dispatch(
        api.util.updateQueryData(
          "Fetch_getmany",
          options?.defaultQueryPayload,
          (draft: any) => {
            draft.data.lists = newState;
          }
        )
      );
    },
    [api, dispatch, options?.defaultQueryPayload]
  );

  const updateStateAt = useCallback(
    (data: any, index: number) => {
      dispatch(
        api.util.updateQueryData(
          "Fetch_getmany",
          options?.defaultQueryPayload,
          (draft: any) => {
            draft.data.lists[index] = data;
          }
        )
      );
    },
    [api, dispatch, options?.defaultQueryPayload]
  );

  if ((!useApi && !options?.optional) || (!api && !options?.optional)) {
    const message = `Seem state "${path}" is not defined in slice "state/tree/api.json"`;
    throw new Error(message);
  }
  if (mutable) {
    return {
      ...(result?.[1] || {}),
      data: result?.[1].data ?? options?.initialData,
      request: result?.[0],
      resetState,
    };
  }
  return {
    ...(result || {}),
    data: result?.data ?? options?.initialData,
    error: result?.error,
    request: result?.refetch,
    resetState,
    updateState,
    updateStateAt,
  };
};

export const setStatefulApiHeaders = (headers: any) => {
  set(global, "statefulApi.headers", headers);
};

export const getStatefulApiHeaders = (): any => {
  return get(global, "statefulApi.headers");
};
